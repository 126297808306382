import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// import Chat from "@material-ui/icons/Chat";
// import VerifiedUser from "@material-ui/icons/VerifiedUser";
// import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/uikit/Grid/GridContainer.js";
import GridItem from "components/uikit/Grid/GridItem.js";
// import InfoArea from "components/uikit/InfoArea/InfoArea.js";
import renderHTML from "react-render-html";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection(props) {
  const { children } = props;
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <div className={classes.description}>{children}</div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
