import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { NoSsr } from "@material-ui/core";
import renderHTML from "react-render-html";
// @material-ui/icons
import Card from "components/atoms/ShowCard";
// core components
import GridContainer from "components/uikit/Grid/GridContainer.js";
import GridItem from "components/uikit/Grid/GridItem.js";
// import Button from "components/uikit/CustomButtons/Button.js";
import Parallax from "components/uikit/Parallax/Parallax.js";
import styles from "assets/jss/material-kit-react/views/mainPage.js";
// Sections for this page
import styled from "styled-components";
import Section from "components/pages/front/Sections/Section";
import logo from "assets/img/logo-dark.svg";
import Grid from "@material-ui/core/Grid";
import { navigate } from "gatsby";

const useStyles = makeStyles(styles);

const BoothItems = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: end;
`;

const BoothItem = styled.div`
  width: 100%;
  max-width: 320px;
  padding-right: 4px;
  padding-bottom: 8px;
  flex: auto;
`;

const LogoImg = styled.img`
  width: 100%;
`;

const Logos = styled.div`
  width: 100%;
  padding: 2rem;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoItem = styled.div`
  width: 100%;
  padding: 10px;
  max-width: 120px;
  flex: auto;
`;

type Props = {
  data: any;
  archiveShows: any[];
  activeShows: any[];
  comingShows: any[];
};

export default function Page(props: Props) {
  const classes = useStyles();
  const { data, activeShows, comingShows, archiveShows } = props;

  console.log(data);
  if (data) {
    return (
      <>
        <Parallax
          filter
          style={{ height: "40vh" }}
          image={data.backgroundImage ? data.backgroundImage.url : null}
        >
          <div className={classes.container}>
            <GridContainer justify="center" alignItems="center">
              <GridItem xs={12} sm={12} md={8}>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  spacing={0}
                >
                  <Grid item>
                    <h1>
                      <img src={logo} width={240} alt={data.title} />
                    </h1>
                  </Grid>
                </Grid>
                {data.discription && (
                  <div className={classes.discription}>
                    {renderHTML(data.discription)}
                  </div>
                )}
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <Section>
              <h2>{data.title}</h2>
              {renderHTML(data.contents)}
            </Section>

            <Section>
              {activeShows.length > 0 && (
                <>
                  <h2>開催中のオンラインショー</h2>
                  <BoothItems>
                    {activeShows.map((show) => (
                      <BoothItem onClick={() => navigate(`/${show.slug}`)}>
                        <Card data={show} />
                      </BoothItem>
                    ))}
                  </BoothItems>
                </>
              )}
            </Section>

            <Section>
              {comingShows.length > 0 && (
                <>
                  <h2>開催予定のオンラインショー</h2>
                  <BoothItems>
                    {comingShows.map((show) => (
                      <BoothItem onClick={() => navigate(`/${show.slug}`)}>
                        <Card data={show} />
                      </BoothItem>
                    ))}
                  </BoothItems>
                </>
              )}
            </Section>

            <Section>
              {archiveShows.length > 0 && (
                <>
                  <h2>過去開催したオンラインショー</h2>
                  <BoothItems>
                    {archiveShows.map((show) => (
                      <BoothItem onClick={() => navigate(`/${show.slug}`)}>
                        <Card data={show} />
                      </BoothItem>
                    ))}
                  </BoothItems>
                </>
              )}
            </Section>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
}
