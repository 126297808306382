import * as React from "react";
import "@aws-amplify/ui/dist/style.css";
import Index from "components/pages/front/Index";
import Layout from "components/layouts/Common";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { graphql } from "gatsby";

type Props = {
  pageContext: any;
  data: any;
};

export default function App(props: Props) {
  const { data, pageContext } = props;

  const page = data.appsync.listTopPages.items
    ? data.appsync.listTopPages.items[0]
    : null;

  return (
    <div className="App">
      <GatsbySeo
        title={
          page && page?.title
            ? page.title
            : "ONLINE BOATSHOW オンラインボートショー"
        }
        description={page && page.description ? page.description : ""}
        openGraph={{
          url: `https://www.onlineboatshow.jp/`,
          title:
            page && page?.title
              ? page.title
              : "ONLINE BOATSHOW オンラインボートショー",
          description: "",
          site_name: "ONLINE BOATSHOW オンラインボートショー",
          images: [
            {
              url: page && page.backgroundImage ? page.backgroundImage.url : "",
              alt: page && page.title ? page.title : "",
            },
          ],
        }}
      />
      {pageContext && page && (
        <Layout>
          <Index
            data={page}
            archiveShows={pageContext.archiveShows}
            activeShows={pageContext.activeShows}
            comingShows={pageContext.comingShows}
          />
        </Layout>
      )}
    </div>
  );
}
export const query = graphql`
  {
    appsync {
      listTopPages(limit: 1) {
        items {
          backgroundImage {
            alternate
            id
            owner
            url
            updatedAt
          }
          contents
          discription
          title
        }
      }
    }
  }
`;
