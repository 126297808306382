import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles({
  root: {
    minheight: 340,
  },
  badge: {
    height: 24,
  },
  media: {
    height: 140,
  },
});

type Props = {
  data: any;
};

export default function ItemCard(props: Props) {
  const { data } = props;
  const classes = useStyles();

  console.log("data:", data);

  return (
    <Card className={classes.root}>
      <CardActionArea>
        {data.ogpImage && (
          <CardMedia
            className={classes.media}
            image={data.ogpImage.url}
            title={data.name}
          />
        )}
        <CardContent>
          <Typography gutterBottom variant="h6">
            {data.title}
          </Typography>
          <Typography gutterBottom variant="body1">
            {data.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
